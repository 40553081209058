/* 微信登录成功后，没有手机号，绑定手机号 */
<template>
  <div class="login-container">
    <div class="login_header">
      <img @click="gotoindex()" src="/imgs/tt-logo.png" alt="" />
    </div>
    <div class="login_content">
      <div class="container">
        <div class="text-content">
          <div class="title">
            天天数链
          </div>
          <div class="describe">
            开源企业、通用算法、分布式算力
          </div>
        </div>
          <div class="card">
            <div class="phone-login">
              <span class="header-title">绑定手机号</span>
            </div>
            <!-- <div class="wx-login">
              <img
                  src="/imgs/to-wx-login.png"
                  class="wx-img"
                  @click="$router.push({name: 'WXLogin'})"
                  alt=""
              >
            </div> -->
            <el-form
                :model="loginFrom"
                class="login-form"
                ref="loginFrom"
                :hide-required-asterisk="true"
                :rules="rules"
            >
              <el-form-item label="手机号" prop="phoneNumber">
                <el-input
                    v-model="loginFrom.phoneNumber"
                    :disabled="disabled"
                    placeholder="请输入手机号码"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="验证码"  prop="smsCode">
                <el-input
                    v-model="loginFrom.smsCode"
                    placeholder="请输入验证码"
                >
                  <template #suffix>
                    <span v-show="getCodeButton" class="getCode" @click="getCode">获取验证码</span>
                    <span v-show="!getCodeButton" class="getCode">{{ time }}后重试</span>
                  </template>
                </el-input>
              </el-form-item>
              <el-button type="primary" @click="login" :style="{width: '100%'}" class="login-form-button" style="margin-top: 8px">
                绑定
              </el-button>
            </el-form>
          </div>

      </div>
    </div>
    <div class="login_footer">
      <div class="login_footer_bottom">
        <p>Copyright ©版权所有 2021- 2022湖北天天数链技术有限公司</p>
        <p class="copyright"><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="openWA" style="margin-right: 20px;">鄂ICP备2020018985号-1</a><span data-v-a3e0e448=""><img data-v-a3e0e448="" src="https://www.tntlinking.com/static/img/bei.png" alt="网安"><a data-v-a3e0e448="" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004850" target="_blank" class="openWA">鄂公网安备 42018502004850号</a></span></p>
      </div>
    </div>
  </div>
</template>
<script>
import { code, login } from '../api/login'
export default {
  name: "BindPhone",
  data() {

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        let patt =  /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
        if(patt.test(value)) {
          callback();
        }
        callback(new Error('请输入正确的手机号码'));
      }
    };

    return {
      loginFrom: {
        phoneNumber: '',
        smsCode: '',
        wechatId: this.$route.query.wechatId,
      },
      rules: {
          phoneNumber: [
            { validator: validatePass, trigger: 'change' }
          ],
          smsCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
      },

      // form: this.$form.createForm(this, {name: 'phone_login'}),
      getCodeButton: true,
      codeForm: {
        mobile: ''
      },
      disabled: false,
      time: 60,
      timer: null
    };
  },
  methods: {
    // 登陆
    login() {
      this.$refs['loginFrom'].validate((valid) => {
        if (valid) {
          let params = {
            mobile: this.loginFrom.phoneNumber,
            smsCode: this.loginFrom.smsCode,
            grant_type: 'sms_code',
            scope: 'all',
            loginChannel: 'Member',
            wechatId: this.$route.query.wechatId,
            register_platform: 'cp',
            register_source: this.$route.query.registerSource,
          }
          login(params)
            .then((res) => {
              if(res.code === 200) {
                localStorage.setItem("token", res.data.access_token);
                // getInfo().then(res => {
                //   if(res.code === 200) {
                //     localStorage.setItem("userInfo", JSON.stringify(res.data));
                //   }
                // } )
                this.gotoindex()
              }
            })
        }
      });
    },
    // 获取验证码
    getCode() {
      this.$refs['loginFrom'].validateField(['phoneNumber'],(err) => {
        if (!err) {
          this.getCodeButton = false;
          this.disabled = true;
          this.timer = setInterval(() => {
            this.time--;
            if (this.time <= 0) {
              this.time = 60;
              this.getCodeButton = true;
              this.disabled = false;
              clearInterval(this.timer);
            }
          }, 1000);
          this.codeForm.mobile = this.loginFrom.phoneNumber
          code(this.codeForm)
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success('短信发送成功')
                } else {
                  this.$message.error('短信发送失败，请稍后再试')
                }
              })
        }
      });
    },
    gotoindex() {
      this.$router.push("/product");
    },
  },
};
</script>
<style lang='scss'>
@import "../assets/scss/login.scss";
</style>
